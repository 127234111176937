@import "../../_globalColor";

.dev-icons {
  padding-left: 0;
  list-style: none;
  font-size: 3rem;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center;
}

.software-skill-inline {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
}
.svg-icon {
  width: 40px; /* Set the desired width */
  height: auto; /* Maintain aspect ratio */
}
.svg-icon:hover {
  filter: brightness(40%); /* Adjust brightness to change color on hover */
}
.svg-icon:hover {
  fill:#47C5FB; /* Change to your theme color */
}
.software-skill-inline > i {
  color: $subTitle;
}

.software-skill-inline > i:hover {
  color: $skillsColor;
}
.software-skill-inline > p {
  color: $subTitle;
  font-size: 10px;
}
.software-skill-inline > i:hover ~ p {
  color: $skillsColor;
}
